import React, {useEffect, useState} from 'react'
import axios from 'axios';
import moment from 'moment';

function Profit(props) {

    
  const [pagenumber, setpagenumber] = useState(props.title);

  const [loading, setloading] = useState(false);
  const [alertcolor, setalertcolor] = useState('green');
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState(null);


  const [sell, setsell] = useState(false);
  const [buy, setbuy] = useState(false);
  const [edit, setedit] = useState(false);

  const [mudate, setmudate] = useState(moment(new Date()).format('yyyy-MM-DD'));

  const [Profit, setProfit] = useState("");
  const [Brokerage, setBrokerage] = useState("");
  const [Tax, setTax] = useState("");
  const [Dividend, setDividend] = useState("");

  const [BShare, setBShare] = useState("");
  const [BScript, setBScript] = useState("");
  const [BQuantity, setBQuantity] = useState("");
  const [BTarget, setBTarget] = useState("");
  const [BPrice, setBPrice] = useState("");
  const [BTotal, setBTotal] = useState("");
  const [SShare, setSShare] = useState("");
  const [SScript, setSScript] = useState("");
  const [SQuantity, setSQuantity] = useState("");
  const [SPrice, setSPrice] = useState("");
  const [STotal, setSTotal] = useState("");
  const [buyData, setBuyData] = useState([]);
  const [Scriptcharge, setScriptcharge] = useState("");
  const [index, setindex] = useState("");
  const [Index, setIndex] = useState("");
  const [BBrokerage, setBBrokerage] = useState("");
  const [Net, setNet] = useState("");


  const [List, setList] = useState([]);
  // const [Page, setPage] = useState(1);

  const emptyCells = new Array(16).fill('');



  const color =(index)=>{
    if ((index+1)% 2===0) {
      return "#D5FFFC"
    } else {
      return "white"
    }
  }
  const imargin =(index)=>{
    if ((index)% 5===0) {
      return "20"
    } else {
      return "0"
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      alert && setalert(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [alert]);

  
  const myalart =(color,msg,btn)=>{
    setalertcolor(color);
    setalertmsg(msg);
    setalert(btn);
  }

  useEffect(()=>{
    if(BQuantity&&BPrice&&Profit){
      setBTotal(parseFloat(BQuantity*BPrice).toFixed(2));
      setBTarget(parseFloat(BQuantity*BPrice*(Profit/100)+BQuantity*BPrice).toFixed(2));
    }
   
  },[BQuantity,BPrice])

  useEffect(()=>{
axios.get("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber)
.then((res)=>{
  const data=res.data
  if(res.data){
    console.log(Object.values(data))
    setList(Object.values(res.data))
    const red = Object.values(res.data)
    setIndex(red.length)
  }else{
    setList([])
    // if(Page<1){
    //   myalart("#DD4444","Page Not Found",true)
    //   setPage(Page+1);
    // }{
    //   myalart("#DD4444","Please Buy Share To Create new Page",true)
      
    // }
  }

})
  },[sell,buy,pagenumber])


  const Buy=async()=>{
    //  console.log(buyData)
  
    const newBuy = {
      BShare,
      BScript,
      BQuantity,
      BPrice:parseFloat(BPrice).toFixed(2),
      BTotal,
      BTarget,
      BBrokerage:parseFloat(BTotal*Brokerage+Scriptcharge).toFixed(0),
      date:mudate
    }
    if(Index<=List.length){
      try { 
        if(Index<1){
          myalart("#DD4444","List in Full Please Go to next Page",true)
      
        }else{
          const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+Index,newBuy)
          console.log(res.data)
          // setPage(Page)
        }
      } catch (error) {
        console.log(error)
      }
    }else{
      for (let i = List.length; i < Index; i++) {
        try {
          const response = await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+i, {
           Name:"Radhe Radhe"
          });
         console.log(response.data)
        } catch (error) {
          console.error('Error posting data:', error);
          // Handle any network or other errors that might occur during the API call
        }
      }
      try { 
        if(Index<1){
          myalart("#DD4444","List in Full Please Go to next Page",true)
      
        }else{
          const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+Index,newBuy)
          console.log(res.data)
          // setPage(Page)
        }
      } catch (error) {
        console.log(error)
      }
  
    }
  
  
  
      // Store buy data
      setBuyData([...buyData, newBuy]);
  
      // Reset Buy fields after storing data
      setIndex('');
      setBShare('');
      setBScript('');
      setBQuantity('');
      setBPrice('');
      setBTotal('');
      setBTarget('');
      setbuy(!buy);
  
    }
  useEffect(()=>{
    if(SQuantity&&SPrice){
      setSTotal(parseFloat(SQuantity*SPrice).toFixed(2))
    }
   
  },[SQuantity,SPrice])


  // useEffect(()=>{
  //   if(Index!==""&&Index>5){
  //     const newindex = Index-5
  //     const mybal = List[newindex].BTotal
  //     const mybalnet = List[newindex].Net
  //     const plus = parseFloat(mybal)+parseFloat(mybalnet)
  //     // console.log(plus);
  //     setObalance(plus)
  //   }
   
  // },[Index,List])

  const Sell=async()=>{
    const S=parseFloat(STotal)
    const B=parseFloat(BTotal)
const Pro=S-B
const Bro=parseFloat((S+B)*(Brokerage/100)+(Scriptcharge*1)).toFixed(2);
const T=parseFloat((Pro-Bro)*Tax/100).toFixed(2)
const Div=parseFloat((Pro-Bro-T)*Dividend/100).toFixed(2)
const value=parseFloat(Pro-Bro-T-Div).toFixed(2)
console.log(typeof STotal, typeof BTotal, typeof Pro,typeof Bro);
console.log(Pro,Bro,STotal,BTotal,S);

const sell={
  BShare,
  BScript,
  BQuantity,
  BPrice,
  BTotal,
  BTarget,
  // BBrokerage:parseFloat(BTotal*Brokerage+Scriptcharge).toFixed(0),
  SShare,
  SScript,
  SQuantity,
  SPrice:parseFloat(SPrice).toFixed(2),
  STotal,
  Scriptcharge,
  Tax:T,
  Dividend:Div,
  Profit:Pro.toFixed(2),
  SBrokerage:Bro,
  Net:value,
  sdate:mudate
}

// console.log(sell)
try {
  const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+(index),sell)
  console.log(res.data)
  const newindex = index
  const mybal = List[newindex].BTotal
  const mybalnet = value
  const plus = (parseFloat(mybal)+parseFloat(mybalnet)).toFixed(2)
  if(index+5<=List.length){
    try {   
const resi=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+(index+5),{Obal:plus})
    } catch (error) {
     console.log(error)
    }
   }else{
     for (let i = List.length; i < index+5; i++) {
       try {
         const response = await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+i, {
          Name:"Radhe Radhe"
         });
        console.log(response.data)
       } catch (error) {
         console.error('Error posting data:', error);
         // Handle any network or other errors that might occur during the API call
       }
     }
     try {   
      const resi=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+(index+5),{Obal:plus})
          } catch (error) {
           console.log(error)
          }
   }
setsell(false);
setBShare('');
  setBScript('');
  setBQuantity('');
  setBPrice('');
  setBTotal('');
  setBTarget('');
  setSShare('');
  setSScript('');
  setSQuantity('');
  setSPrice('');
  setSTotal('');

} catch (error) {
  console.log(error)
}


   
  }

  const addnet=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Net) {
      sumAmount += parseFloat(List[i].Net);
      }
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const adddivident=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Dividend) {
      sumAmount += parseFloat(List[i].Dividend);
      }
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const addtax=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Tax) {
      sumAmount += parseFloat(List[i].Tax);
      }
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const addbroker=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].SBrokerage) {
      sumAmount += parseFloat(List[i].SBrokerage);
    }
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const addprofit=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Profit) {
      sumAmount += parseFloat(List[i].Profit);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }
  const addbuyvalue=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].BTotal) {
      sumAmount += parseFloat(List[i].BTotal);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }
  const addsellvalue=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].STotal) {
      sumAmount += parseFloat(List[i].STotal);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }
  const addopen=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Obal) {
      sumAmount += parseFloat(List[i].Obal);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }
  
const [TopNet, setTopNet] = useState("0");
const [topp, settopp] = useState("");
const [topb, settopb] = useState("");
const [topt, settopt] = useState("");
const [topd, settopd] = useState("");
  const topadd=()=>{
    let sumAmount = 0;
    let sump = 0;
    let sumb = 0;
    let sumt = 0;
    let sumd = 0;
    if (List.length>0) {
    for (let i = 1; i < List.length; i++) {
      if (List[i].Net) {
        sumAmount += parseFloat(List[i].Net);
        sump += parseFloat(List[i].Profit);
        sumb += parseFloat(List[i].SBrokerage);
        sumt += parseFloat(List[i].Tax);
        sumd += parseFloat(List[i].Dividend);
      }
    }
    setTopNet(parseFloat(sumAmount).toFixed(2));
    settopp(parseFloat(sump).toFixed(2));
    settopb(parseFloat(sumb).toFixed(2));
    settopt(parseFloat(sumt).toFixed(2));
    settopd(parseFloat(sumd).toFixed(2));
    } else {
      setTopNet(sumAmount);
      settopp(sump);
      settopb(sumb);
      settopt(sumt);
      settopd(sumd);
    }
  }
  useEffect(() => {
    topadd()
  }, [List])
useEffect(()=>{
  axios.get("https://compounding.duniyatech.com/api/compounding-app/mydata")
  .then((res)=>{
    const d=res.data.mydata
    // console.log(d)
    setProfit(d.Profit);
    setBrokerage(d.Brokerage);
    setDividend(d.Dividend);
    setScriptcharge(d.Scriptcharge);
    setTax(d.Tax);

  })
},[])

const Update=async()=>{
  const data={
Profit,
Brokerage,
Scriptcharge,
Tax,
Dividend
  }
 const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/mydata/mydata",data)
 console.log(res.data)
 setedit(false);
 myalart("green","Updated Successfully",true);
}
  


    return (


      <div style={{
        width:"100%"
    }}>
  
  
  {alert?<><div style={{
    width:"100%",
    backgroundColor:alertcolor,
    color:"white",
    textAlign:'center',
    padding:8,
  }}>
    <h6 style={{fontSize:12}}>{alertmsg}</h6>
  </div></>:<></>}
  
  
  
        
        <div style={{
        margin:"20px 2% 0px 2%",
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
    }}>


  <div style={{top:0,right:0,left:0,display:'flex',flexDirection:'row',
        flexFlow:'wrap',backgroundColor:'white',padding:'0 1% 0 1%'}}>


  <div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Input Details<i className='fa-solid fa-pen-fancy'onClick={()=>setedit(true)} style={{marginLeft:5}}>_</i></div>
  
  
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Profit(%)</label>
  <input value={Profit} onChange={(e)=>setProfit(e.target.value)} placeholder='Enter Values' style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  
  {/* <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Profit Amount</label>
  <input value={PAmount} onChange={(e)=>setPAmount(e.target.value)} placeholder='Enter Values' style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div> */}
  
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Brokerage</label>
  <input value={Brokerage} onChange={(e)=>setBrokerage(e.target.value)} placeholder='Enter Values' style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Script Charge</label>
  <input value={Scriptcharge} onChange={(e)=>setScriptcharge(e.target.value)} placeholder='Enter Values' style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Tax</label>
  <input value={Tax} onChange={(e)=>setTax(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Dividend</label>
  <input value={Dividend} onChange={(e)=>setDividend(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
{
  edit?<div style={{padding:5, textAlign:"end",width:"100%"}}>
  <button onClick={Update} style={{borderRadius:2,padding:"5px 30px 5px 30px", fontSize:12,backgroundColor:"#322869",color:'white',fontWeight:"bold"}} type="button">UPDATE</button>
  </div>:<div style={{width:"100%",margin:10}}>
  </div>
}
  
  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,fontWeight:700}}>Total Profit - {topp}</label>
  </div>

  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700}}>Total Brokerage - {topb}</label>
  </div>

  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700}}>Total Tax - {topt}</label>
  </div>

  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700}}>Total Dividend - {topd}</label>
  </div>

  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700}}>Total Net Value - {TopNet}</label>
  </div>

  
  
  {
    sell?<>
    <div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Sell</div>
  {/* <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>S No</label>
  <input value={SShare} onChange={(e)=>setSShare(e.target.value)} placeholder="Enter value" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
  </div> */}
  
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
  <input value={mudate} onChange={(e)=>setmudate(e.target.value)} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='date'/>
  </div>
  
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Share Name</label>
  <input value={SShare} onChange={(e)=>setSShare(e.target.value)} placeholder="Enter Name" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Script</label>
  <input value={SScript} onChange={(e)=>setSScript(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Sell Quantity</label>
  <input value={SQuantity} onChange={(e)=>setSQuantity(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Price</label>
  <input value={SPrice} onChange={(e)=>setSPrice(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Total Value</label>
  <input value={STotal} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  
  {
  loading? <div style={{width:"100%", height:100, textAlign:"end"}}>
  <div class="lds-hourglass"></div><h6 style={{margin:'15px 5px 0px 0px'}}>Please wait...</h6>
  </div>
  :
  <div style={{width:"100%",padding:5, textAlign:"end"}}>

 {SPrice!==""&&  <button onClick={Sell} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"10px 10px 0px 0px", fontSize:12,backgroundColor:"#636694"}} type="button">SUBMIT</button>
  }
  </div>
  }
    </>:<>
    <div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Buy</div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Index</label>
  <input value={Index} onChange={(e)=>setIndex(e.target.value)} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
  <input value={mudate} onChange={(e)=>setmudate(e.target.value)} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='date'/>
  </div>
  {/* <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Opening Bal.</label>
  <input value={Obalance} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div> */}
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Share Name</label>
  <input value={BShare} onChange={(e)=>setBShare(e.target.value)} placeholder="Enter Name" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Script</label>
  <input value={BScript} onChange={(e)=>setBScript(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buy Quantity</label>
  <input value={BQuantity} onChange={(e)=>setBQuantity(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Price</label>
  <input value={BPrice} onChange={(e)=>setBPrice(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Total Value</label>
  <input value={BTotal}  placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Expected Target</label>
  <input value={BTarget} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>

{BShare!==""&&BScript!==""&&BQuantity!==""&&BPrice!==""&&Index!==""&&
  <div style={{padding:5, textAlign:"end",width:"100%"}}>
  <button onClick={Buy} style={{borderRadius:2,padding:"5px 30px 5px 30px", fontSize:12,backgroundColor:"#322869",color:'white',fontWeight:"bold"}} type="button">SUBMIT</button>
  </div>}


    </>
  }
  

  
  </div>
  <div style={{width:'100%',height:"40vh",overflowY:"scroll",padding:0}}>
  <table style={{textAlign:"center",width:"100%",position:"relative"}}>
      <thead style={{width:"100%"}}>
                  <tr style={{backgroundColor:"#322869",color:'white',fontSize:12,position:"sticky",top:0}}>
                      <th style={{padding:5}}>S.no.</th>
                      <th >Opening Bal.</th>
                      <th>Date</th>
                      
                      <th >Share</th>
                      <th >Script</th>
                      <th >Buy Qty</th>
                      <th >Price</th>
                      <th >Value</th>
                      <th >Exp. Target</th>
                      {/* <th>Buy Brokerage</th> */}
                      <th ></th>
                      <th >Sell Date</th>
                      <th >Sell Qty</th>
                      <th >Price</th>
                      <th >Sell value</th>
                      <th >Profit</th>
                      <th >Brokerage</th>
                      {/* <th>Script Charge</th> */}
                      <th >Tax</th>
                      <th >Dividend</th>
                      <th >Net Value</th>
                  </tr>
       </thead>
                  <tbody style={{overflowY:"hidden",overflow:"auto"}}>

      
                    {List&&List.map((item , index)=>(
                      item===null?<>
                       <tr style={{fontSize:12,backgroundColor:color(index)}}>
                <td style={{padding:5}}>{index}</td>
                 {emptyCells.map((cell, index) => (
                <td key={index}>{cell}</td>
                ))}
              </tr>
                      </>:<>
  
                 
                        <tr key={index} style={{fontSize:12,backgroundColor:color(index),textAlign:"end"}}>
                          <td style={{padding:5,textAlign:"center"}}>{index}</td>
                          <td style={{fontWeight:"bold"}}>{item.Obal}</td>
                          <td style={{textAlign:"center"}}>{item.date}</td>
                          
                          <td style={{textAlign:"left"}}>{item.BShare}</td>
                          <td style={{textAlign:"left"}}>{item.BScript}</td>
                          <td style={{textAlign:"center"}}>{item.BQuantity}</td>
                          <td >{item.BPrice}</td>
                          <td >{item.BTotal}</td>
                          <td >{item.BTarget}</td>
                          {/* <td>{item.BBrokerage}</td> */}
                          <td style={{textAlign:"center"}} ><button onClick={()=>(setSShare(item.BShare),
                            setBScript(item.BScript),
                            setindex(index),
                            setBShare(item.BShare),
                            setBQuantity(item.BQuantity),
                            setBPrice(item.BPrice),
                            setBTotal((item.BTotal)),
                            setBTarget(item.BTarget),
                            setBBrokerage(item.BBrokerage),
                            setSScript(item.BScript),
                            setSQuantity(item.BQuantity),
                            setsell(true)
                            )}>sell</button></td>
                            <td style={{textAlign:"center"}}>{item.sdate}</td>
                            <td style={{textAlign:"center"}}>{item.SQuantity}</td>
                            <td >{item.SPrice}</td>
                            <td >{item.STotal}</td>
                            <td >{item.Profit}</td>
                            <td >{item.SBrokerage}</td>
                            {/* <td>{item.Scriptcharge}</td> */}
                            <td >{item.Tax}</td>
                            <td >{item.Dividend}</td>
                            <td style={{paddingRight:5}} >{item.Net}</td>
  
  
                        </tr>
  
                        {
                        imargin(index)==="0"?<></>:<>
                        <tr style={{backgroundColor:'#D9E1FF',fontSize:12,fontWeight:700,textAlign:"end"}}>
                          <td style={{backgroundColor:'#D9E1FF',padding:5}}>{">"}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{addopen(index)}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{addbuyvalue(index)}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{addsellvalue(index)}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{addprofit(index)}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{addbroker(index)}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{addtax(index)}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{adddivident(index)}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}>{addnet(index)}</td>
                      
                        </tr>

                        

                        </>
                      }

                     
                        </>
                      ))
                      
                    } 


                    {/* {
                List.length!==0&&  <tr style={{fontSize:12,backgroundColor:'#D9E1FF',fontWeight:'bold',marginBottom:"50px"}}>
                <td style={{padding:5}}>{'>'}</td>
                 {emptyCells.map((cell, index) => (
                <td key={index}>{cell}</td>
                ))}
              </tr>
              } 
               */}
    
                 
              </tbody>
              </table>  
              </div>
              {/* <div style={{width:"100%", height:60, textAlign:"end"}}>
  <button onClick={()=>setPage(Page-1)} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#636694"}} type="button">Prev</button>
  <span style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12}}>{Page}</span>
  <button onClick={()=>setPage(Page+1)} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#636694"}} type="button">Next</button>
  </div>  */}



  
  
  
    </div>
    </div>
    );
  
  
  //   return(
  //     <div>
  //        <div style={{display:"flex",height:"100vh",justifyContent:"center",alignItems:"center"}}>
  //        <div style={{padding:5,textAlign:'end'}}>
  // <label style={{fontSize:12,margin:"0px 0px 20px 0px"}}>UserID</label>
  // <input onChange={(e)=>{if (e.target.value==="admin1") {
  //   setpagenumber("1");
    
  // } else if(e.target.value==="admin2"){
  //   setpagenumber("2");
    
    
  // }else if (e.target.value==="admin3") {
  //   setpagenumber("3");
  
    
  // }else{
  //   setpagenumber("0");
 

  // }}} placeholder="Enter Username" style={{fontSize:12,padding:"3px 10px 5px 10px",marginBottom:20}} type='text'/>
  // <br></br>
  // <label style={{fontSize:12,margin:"0px 0px 0px 0px"}}>Password</label>
  // <input onChange={(e)=>{if (e.target.value==="RadheRadhe") {
  //   if (pagenumber==="0") {
  //     setlogin(false)
  //   } else {
  //     setlogin(true)
  //   }
    
  // }else{setlogin(false)}}} placeholder="Enter Password" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='password'/>
  // </div>

  //        </div>
  //     </div>
  //   )
  
}

export default Profit
