import React, {useEffect, useState} from 'react'
import axios from 'axios';
import moment from 'moment';
function Loss(props) {

    
  const [pagenumber, setpagenumber] = useState(200+parseInt(props.title));

  const [loading, setloading] = useState(false);
  const [alertcolor, setalertcolor] = useState('green');
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState(null);


  const [sell, setsell] = useState(false);
  const [buy, setbuy] = useState(false);
  const [edit, setedit] = useState(false);

  const [mudate, setmudate] = useState(moment(new Date()).format('yyyy-MM-DD'));

  const [Profit, setProfit] = useState("");
  const [Brokerage, setBrokerage] = useState("");
  const [Tax, setTax] = useState("");
  const [Dividend, setDividend] = useState("");

  const [BShare, setBShare] = useState("");
  const [BScript, setBScript] = useState("");
  const [BQuantity, setBQuantity] = useState("");
  const [BTarget, setBTarget] = useState("");
  const [BPrice, setBPrice] = useState("");
  const [BTotal, setBTotal] = useState("");
  const [SShare, setSShare] = useState("");
  const [SScript, setSScript] = useState("");
  const [SQuantity, setSQuantity] = useState("");
  const [SPrice, setSPrice] = useState("");
  const [STotal, setSTotal] = useState("");
  const [buyData, setBuyData] = useState([]);
  const [Scriptcharge, setScriptcharge] = useState("");
  const [index, setindex] = useState("");
  const [Index, setIndex] = useState("");
  const [BBrokerage, setBBrokerage] = useState("");
  const [Net, setNet] = useState("");


  const [List, setList] = useState([]);
  // const [Page, setPage] = useState(1);

  const emptyCells = new Array(16).fill('');



  const color =(index)=>{
    if ((index+1)% 2===0) {
      return "#D5FFFC"
    } else {
      return "white"
    }
  }
  const imargin =(index)=>{
    if ((index)% 5===0) {
      return "20"
    } else {
      return "0"
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      alert && setalert(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [alert]);

  
  const myalart =(color,msg,btn)=>{
    setalertcolor(color);
    setalertmsg(msg);
    setalert(btn);
  }

  useEffect(()=>{
    if(BQuantity&&BPrice&&Profit){
      setBTotal(parseFloat(BQuantity*BPrice).toFixed(2));
      setBTarget(parseFloat(BQuantity*BPrice*(Profit/100)+BQuantity*BPrice).toFixed(2));
    }
   
  },[BQuantity,BPrice])

  useEffect(()=>{
    if(SQuantity&&SPrice){
      setSTotal(parseFloat(SQuantity*SPrice).toFixed(2))
    }
   
  },[SQuantity,SPrice])

    const get=()=>{
axios.get("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber)
.then((res)=>{
  const data=res.data
  if(res.data){
    console.log(Object.values(data))
    setList(Object.values(res.data))
    const red = Object.values(res.data)
    setIndex(red.length)
  }else{
    setList([])
    // if(Page<1){
    //   myalart("#DD4444","Page Not Found",true)
    //   setPage(Page+1);
    // }{
    //   myalart("#DD4444","Please Buy Share To Create new Page",true)
      
    // }
  }

}).catch((error)=>console.log("List Error",error))
}
useEffect(()=>{
  get()
  },[sell,buy,pagenumber])


const Add=async()=>{
  const Bro=(BTotal*1+STotal*1)*(Brokerage/100)+Scriptcharge*1
  const loss=(BTotal-STotal)+Bro
  const Bal=BTotal*1-loss
  const Recovery=BTotal*1+Bro+loss*Tax/100
  const Percent=(Recovery-Bal)/Bal*100
  console.log("Loss:",loss,"Amt:",Bal,"Recovery:",Recovery,"Percent:",Percent,)
  const trade={
      BShare,
      BQuantity,
      BPrice:parseFloat(BPrice).toFixed(2),
      BTotal,
      SPrice:parseFloat(SPrice).toFixed(2),
      STotal,
      sdate:mudate,
      Brokerage:parseFloat(Bro).toFixed(2),
      loss:parseFloat(loss).toFixed(2),
      Bal:parseFloat(Bal).toFixed(2),
      Recovery:parseFloat(Recovery).toFixed(2),
      Percent:parseFloat(Percent).toFixed(2),
  }

  if(Index<=List.length){
    try { 
      if(Index<1){
        myalart("#DD4444","List in Full Please Go to next Page",true)
    
      }else{
        const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+Index,trade)
        console.log(res.data)
        get()
        // setPage(Page)
      }
    } catch (error) {
      console.log(error)
    }
  }else{
    for (let i = List.length; i < Index; i++) {
      try {
        const response = await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+i, {
         Name:"Radhe Radhe"
        });
       console.log(response.data)
      } catch (error) {
        console.error('Error posting data:', error);
        // Handle any network or other errors that might occur during the API call
      }
    }
    try { 
      if(Index<1){
        myalart("#DD4444","List in Full Please Go to next Page",true)
    
      }else{
        const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+Index,trade)
        console.log(res.data)
        get()
        // setPage(Page)
      }
    } catch (error) {
      console.log(error)
    }

  }
  setBShare('');
  setBQuantity('');
  setBPrice('');
  setBTotal('');
  setSQuantity('');
  setSPrice('');
  setSTotal('');

}

const newtrade=async()=>{

  const Bro=(BTotal*1+STotal*1)*(Brokerage/100)+Scriptcharge*1
  const Profit=(STotal-BTotal)
  const T=(Profit-Bro)*Tax/100
  const net=STotal-Bro-T
  const netprofit=Profit-Bro-T
  console.log(Bro,Profit,T,net)

  const trade={
    NBShare:BShare,
    NBQuantity:BQuantity,
    NBPrice:parseFloat(BPrice).toFixed(2),
    NBTotal:BTotal,
    NSPrice:parseFloat(SPrice).toFixed(2),
    NSTotal:STotal,
    Nsdate:mudate,
    NetBalance:parseFloat(net).toFixed(2),
    Tax:parseFloat(T).toFixed(2),
    Profit:parseFloat(netprofit).toFixed(2),
  }
  try {
    const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/"+pagenumber+"/"+index,trade)
    console.log(res.data)
    get()
  } catch (error) {
    console.log(error)
  }
  setBShare('');
  setBQuantity('');
  setBPrice('');
  setBTotal('');
  setSQuantity('');
  setSPrice('');
  setSTotal('');
  setsell(false)


}



  const addnet=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Net) {
      sumAmount += parseFloat(List[i].Net);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const adddivident=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Dividend) {
      sumAmount += parseFloat(List[i].Dividend);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const addtax=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Tax) {
      sumAmount += parseFloat(List[i].Tax);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const addbroker=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].SBrokerage) {
      sumAmount += parseFloat(List[i].SBrokerage);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }

  const addprofit=(index)=>{
    let sumAmount = 0;
    for (let i = index-4; i < index+1; i++) {
      if (List[i].Profit) {
      sumAmount += parseFloat(List[i].Profit);}
    }
    return parseFloat(sumAmount).toFixed(2)
  }
  
const [TopNet, setTopNet] = useState("0");
const [topp, settopp] = useState("");
const [topb, settopb] = useState("");
const [topt, settopt] = useState("");
const [topd, settopd] = useState("");


  const topadd=()=>{
    let sumAmount = 0;
    let sump = 0;
    let suml = 0;
    let sumt = 0;
    let sumd = 0;
    if (List.length>0) {
    for (let i = 1; i < List.length; i++) {
      if (List[i].Profit) {
        // sumAmount += parseFloat(List[i].Net);
        sump += parseFloat(List[i].Profit);
       
        // sumt += parseFloat(List[i].Tax);
        // sumd += parseFloat(List[i].Dividend);
      }
      if(List[i].loss){
        suml += parseFloat(List[i].loss);
      }
    }
    setTopNet(parseFloat(sumAmount).toFixed(2));
    settopp(parseFloat(sump).toFixed(2));
    settopb(parseFloat(suml).toFixed(2));
    settopt(parseFloat(sumt).toFixed(2));
    settopd(parseFloat(sumd).toFixed(2));
    } else {
      setTopNet(sumAmount);
      settopp(sump);
      settopb(suml);
      settopt(sumt);
      settopd(sumd);
    }
  }
  useEffect(() => {
    topadd()
  }, [List])



useEffect(()=>{
  axios.get("https://compounding.duniyatech.com/api/compounding-app/mydata")
  .then((res)=>{
    const d=res.data.mydata
    // console.log(d)
    setProfit(d.Profit);
    setBrokerage(d.Brokerage);
    setDividend(d.Dividend);
    setScriptcharge(d.Scriptcharge);
    setTax(d.Tax);

  })
},[])

const Update=async()=>{
  const data={
Profit,
Brokerage,
Scriptcharge,
Tax,
Dividend
  }
 const res=await axios.post("https://compounding.duniyatech.com/api/compounding-app/mydata/mydata",data)
 console.log(res.data)
 setedit(false);
 myalart("green","Updated Successfully",true);
}

    return (


      <div style={{
        width:"100%"
    }}>
  
  
  {alert?<><div style={{
    width:"100%",
    backgroundColor:alertcolor,
    color:"white",
    textAlign:'center',
    padding:8,
  }}>
    <h6 style={{fontSize:12}}>{alertmsg}</h6>
  </div></>:<></>}
  
  
  
        
        <div style={{
        margin:"20px 2% 0px 2%",
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
    }}>


  <div style={{top:0,right:0,left:0,display:'flex',flexDirection:'row',
        flexFlow:'wrap',backgroundColor:'white',padding:'0 1% 0 1%'}}>


  <div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Input Details Loss<i className='fa-solid fa-pen-fancy'onClick={()=>setedit(true)} style={{marginLeft:5}}>_</i></div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Brokerage</label>
  <input value={Brokerage} onChange={(e)=>setBrokerage(e.target.value)} placeholder='Enter Values' style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Script Charge</label>
  <input value={Scriptcharge} onChange={(e)=>setScriptcharge(e.target.value)} placeholder='Enter Values' style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Tax</label>
  <input value={Tax} onChange={(e)=>setTax(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
{
  edit?<div style={{padding:5, textAlign:"end",width:"100%"}}>
  <button onClick={Update} style={{borderRadius:2,padding:"5px 30px 5px 30px", fontSize:12,backgroundColor:"#322869",color:'white',fontWeight:"bold"}} type="button">UPDATE</button>
  </div>:<div style={{width:"100%",margin:10}}>
  </div>
}
  

  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700}}>Total Loss - {topb}</label>
  </div>
  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,fontWeight:700}}>Total Recovery - {topp}</label>
  </div>

  {/* <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700}}>Total Tax - {topt}</label>
  </div>

  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700}}>Total Dividend - {topd}</label>
  </div> */}

  <div style={{padding:'5px 15px 5px 15px',height:20, textAlign:'center', backgroundColor:'#F0F0F0',borderRadius:3,margin:'0 0 5px 30px',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 15px",fontWeight:700,}}>Difference -<span style={{color:topb>topp?"red":"green"}}> {parseFloat(topb-topp).toFixed(2)}</span></label>
  </div>

{
  sell?<>
  <div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>New Trade Details- Recovery</div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Index</label>
  <input value={index} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
  <input value={mudate} onChange={(e)=>setmudate(e.target.value)} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px",width:145}} type='date'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Share Name</label>
  <input value={BShare} onChange={(e)=>setBShare(e.target.value)} placeholder="Enter Name" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buy Price</label>
  <input value={BPrice} onChange={(e)=>setBPrice(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buy Quantity</label>
  <input value={BQuantity} onChange={(e)=>setBQuantity(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buy Value</label>
  <input value={BTotal}  placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Sell Price</label>
  <input value={SPrice} onChange={(e)=>setSPrice(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Sell Quantity</label>
  <input value={SQuantity} onChange={(e)=>setSQuantity(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Sell Value</label>
  <input value={STotal} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{width:"100%",padding:5, textAlign:"end"}}>
 {SPrice!==""&&  <button onClick={newtrade} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"10px 10px 0px 0px", fontSize:12,backgroundColor:"#322869",color:'white'}} type="button">SUBMIT</button>
  }
  </div>

  </>:<>

   <div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>previous Trade Details</div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Index</label>
  <input value={Index} onChange={(e)=>setIndex(e.target.value)} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
  <input value={mudate} onChange={(e)=>setmudate(e.target.value)} placeholder="Enter Index No" style={{fontSize:12,padding:"3px 10px 5px 10px",width:145}} type='date'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Share Name</label>
  <input value={BShare} onChange={(e)=>setBShare(e.target.value)} placeholder="Enter Name" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buy Price</label>
  <input value={BPrice} onChange={(e)=>setBPrice(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buy Quantity</label>
  <input value={BQuantity} onChange={(e)=>setBQuantity(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buy Value</label>
  <input value={BTotal}  placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Sell Price</label>
  <input value={SPrice} onChange={(e)=>setSPrice(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Sell Quantity</label>
  <input value={SQuantity} onChange={(e)=>setSQuantity(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Sell Value</label>
  <input value={STotal} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
  <div style={{width:"100%",padding:5, textAlign:"end"}}>
 {SPrice!==""&&  <button onClick={Add} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"10px 10px 0px 0px", fontSize:12,backgroundColor:"#322869",color:'white'}} type="button">SUBMIT</button>
  }
  </div></>
}
 

  
  </div>
  <div style={{width:'100%',height:"40vh",overflowY:"scroll",padding:0}}>
  <table style={{textAlign:"center",width:"100%",position:"relative"}}>
      <thead style={{width:"100%"}}>
                  <tr style={{backgroundColor:"#322869",color:'white',fontSize:12,position:"sticky",top:0}}>
                      <th style={{padding:5}}>S.no.</th>
                      <th>Date</th>
                      <th >Share</th>
                      <th > Qty</th>
                      <th > Buy Price</th>
                      <th >Buy Value</th>
                      <th >Sell Price</th>
                      <th >Sell value</th>
                      <th >Brokerage</th>
                      <th >Loss</th>
                      <th >Bal/Invest</th>
                      <th >Exp Recovery</th>
                      <th >Req (%)</th>
                      <th ></th>

                      <th >Date</th>
                      <th >Share</th>
                      <th >Qty</th>
                      <th > Buy Price</th>
                      <th >Buy Value</th>
                      <th >Sell Price</th>
                      <th >Sell value</th>
                      <th >Tax</th>
                      <th >Net Profit</th>
                      <th >Net value</th>
                  </tr>
       </thead>
                  <tbody style={{overflowY:"hidden",overflow:"auto"}}>

      
                    {List&&List.map((item , index)=>(
                      item===null?<>
                       <tr style={{fontSize:12,backgroundColor:color(index)}}>
                <td style={{padding:5}}>{index}</td>
                 {emptyCells.map((cell, index) => (
                <td key={index}>{cell}</td>
                ))}
              </tr>
                      </>:<>
  
                 
                        <tr key={index} style={{fontSize:12,backgroundColor:color(index),textAlign:"end"}}>
                          <td style={{padding:5,textAlign:"center"}}>{index}</td>
                          <td style={{textAlign:"center"}}>{item.sdate}</td>
                          <td style={{textAlign:"left"}}>{item.BShare}</td>
                          <td style={{textAlign:"center"}}>{item.BQuantity}</td>
                          <td >{item.BPrice}</td>
                          <td >{item.BTotal}</td>
                            <td >{item.SPrice}</td>
                            <td >{item.STotal}</td>
                            <td >{item.Brokerage}</td>

                            <td >{item.loss}</td>
                            <td >{item.Bal}</td>
                            <td style={{fontWeight:"bold"}} >{item.Recovery}</td>
                            <td >{item.Percent}</td>
                            <td style={{textAlign:"center"}}><button onClick={()=>(setsell(true),setindex(index))}>Trade</button></td>
                            <td style={{textAlign:"center"}}>{item.Nsdate}</td>
                          <td style={{textAlign:"left"}}>{item.NBShare}</td>
                          <td style={{textAlign:"center"}}>{item.NBQuantity}</td>
                          <td >{item.NBPrice}</td>
                          <td >{item.NBTotal}</td>
                          <td >{item.NSPrice}</td>
                            <td >{item.NSTotal}</td>
                            <td >{item.Tax}</td>
                            <td >{item.Profit}</td>
                            <td >{item.NetBalance}</td>
  
  
                        </tr>
  
                        {
                        imargin(index)==="0"?<></>:<>
                        <tr style={{backgroundColor:'#D9E1FF',fontSize:12,fontWeight:700,textAlign:"end"}}>
                          <td style={{backgroundColor:'#D9E1FF',padding:5}}>{">"}</td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                          <td style={{backgroundColor:'#D9E1FF'}}></td>
                        </tr>

                        

                        </>
                      }

                     
                        </>
                      ))
                      
                    } 


                    
    
                 
              </tbody>
              </table>  
              </div>
        


  
  
  
    </div>
    </div>
    );
  
}

export default Loss
