import React, {useEffect, useState} from 'react'
import axios from 'axios';
import moment from 'moment';
import { Route, Routes } from 'react-router-dom';
import Profit from './Components/Profit';
import Calculator from './Components/Calculator';
import Loss from './Components/Loss';
import { Link } from 'react-router-dom';

function App (){

  const [login, setlogin] = useState(false);
  const [pagenumber, setpagenumber] = useState("1");
  if(login){
    return(
      <>

      <div style={{textAlign:"end",display:"flex",justifyContent:"space-around",alignItems:"center",boxShadow:"2px 2px 10px grey",margin:"20px 2%",borderRadius:"5px"}}>
        <div><h1>Compounding Investment</h1></div>
       <div style={{padding:5}}>
 <Link to={"./"} ><button style={{borderRadius:2,padding:"8px 30px 8px 30px",color:'green' ,fontSize:12,fontWeight:"bold",border:"1px solid green"}} type="button"><i className='fa-solid fa-arrow-up'></i> Profit</button></Link> 
 <Link to ="./Loss"><button style={{borderRadius:2,padding:"8px 30px 8px 30px", fontSize:12,color:"red",fontWeight:"bold",marginLeft:"30px",border:"1px solid red"}} type="button"><i className='fa-solid fa-arrow-down'></i> Loss</button></Link> 
 <Link to ="./Calc"><button style={{borderRadius:2,padding:"8px 30px 8px 30px", fontSize:12,color:"grey",fontWeight:"bold",marginLeft:"30px",border:"1px solid grey"}} type="button"><i className='fa-solid fa-calculator'></i> Calculator</button></Link> 
  </div>
      </div>
      <Routes>
        <Route path='/' element={<Profit title={pagenumber}/>}/>
        <Route path='/Loss' element={<Loss title={pagenumber}/>} />
        <Route path='/Calc' element={<Calculator title={pagenumber}/>} />
      </Routes>
      </>
    )}else {

      return(
        <div>
           <div style={{display:"flex",height:"100vh",justifyContent:"center",alignItems:"center"}}>
           <div style={{padding:5,textAlign:'end'}}>
    <label style={{fontSize:12,margin:"0px 0px 20px 0px"}}>UserID</label>
    <input onChange={(e)=>{if (e.target.value==="admin1") {
      setpagenumber("1");
      
    }else if (e.target.value==="admin2") {
      setpagenumber("2");
      
    }else if (e.target.value==="admin3") {
      setpagenumber("3");
      
    }else{
      setpagenumber("0");
    
    
    }}} placeholder="Enter Username" style={{fontSize:12,padding:"3px 10px 5px 10px",marginBottom:20}} type='text'/>
    <br></br>
    <label style={{fontSize:12,margin:"0px 0px 0px 0px"}}>Password</label>
    <input onChange={(e)=>{if (e.target.value==="RadheRadhe") {
      if (pagenumber==="0") {
        setlogin(false)
      } else {
        setlogin(true)
      }
      
    }else{setlogin(false)}}} placeholder="Enter Password" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='password'/>
    </div>
    
           </div>
        </div>
      )
  }
}


export default App;
