import React, { useState } from 'react'



function Calculator() {

  const [buyprice, setbuyprice] = useState("");
  const [prebuy, setprebuy] = useState("");
  const [Scharge, setScharge] = useState(12);

  return (
    <div style={{
      width:"100%"
  }}>

<div style={{
        margin:"20px 2% 0px 2%",
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
    }}>

<div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Input Details</div>
  
        <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Buying Price</label>
  <input value={buyprice} onChange={(e)=>setbuyprice(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Previous B. Price</label>
  <input value={prebuy} onChange={(e)=>setprebuy(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Script charges</label>
  <input value={Scharge} onChange={(e)=>setScharge(e.target.value)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>

<div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"20px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Results</div>


  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Brokerage</label>
  <input value={((parseFloat(buyprice)+parseFloat(prebuy))*0.0012).toFixed(2)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Expected Target</label>
  <input value={(parseFloat(prebuy)+(((parseFloat(buyprice)+parseFloat(prebuy))*0.0012)*2)+(parseFloat(Scharge)*2)).toFixed(2)} placeholder="Enter values" style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
  </div>
    </div>
    </div>
  )
}

export default Calculator
